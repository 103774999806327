<template>
  <div :class="flexClassName">
    <div :style="pieStyle">
      <pie-single
        :data="data"
        :options="options"
        :events="events"
        :height="height"
        :width="width"
      />
    </div>
    <div :style="legendStyles">
      <chart-legend
        v-if="isLegend"
        :items="legend"
        :animationDuration="animationDuration"
        :options="legendOptions"
      />
    </div>
  </div>
</template>

<script>
import PieSingle from './PieSingle'
import ChartLegend from './ChartLegend'
import { scaleOrdinal, schemeCategory10 } from 'd3'
import { mapGetters } from 'vuex'

/*
  color, scaleOrdinal, schemeCategory10, animationDuration
  дублируются с графиком. Возможно стоит сделать этот компонент единым входом в график
  А из графика их убрать совсем
  Или зашить в миксин?
*/

export default {
  components: {
    PieSingle,
    ChartLegend,
  },
  props: {
    height: {
      type: Number,
      // todo: Высоту сделал подгоном. Нужно будет поработать с высотой.
      default: 230,
    },
    width: {
      type: Number,
      default: 230,
    },
    data: {
      type: Object,
      required: true,
    },
    options: {
      type: Object,
      required: true,
    },
    events: {
      type: Object,
      required: false,
    },
    legendOptions: {
      type: Object,
      required: true,
      validator(legend) {
        /* You may want to turn this rule off if your code only touches objects with hardcoded keys,
          and you will never use an object that shadows an Object.prototype method
          or which does not inherit from Object.prototype.
        */
        // eslint-disable-next-line no-prototype-builtins
        if (!legend.hasOwnProperty('reversed')) legend.reversed = false
        return true
      },
    },
  },
  computed: {
    ...mapGetters({
      otherId: 'dbd/getOtherID',
    }),
    pieStyle() {
      return `width: ${this.width}px; height: ${this.height}px; flex-shrink: 0`
    },
    flexClassName() {
      const flexHelpers = {
        right: 'flex-row',
        left: 'flex-row-reverse',
        bottom: 'flex-column',
        top: 'flex-column-reverse',
      }
      return `d-flex ${flexHelpers[this.options.legendPosition]} `
    },
    legend() {
      const legend = this.data.names
        .map((d, i) => ({
          id: i,
          name: d,
          color: this.color(i),
        }))
        .filter((item, index) => this.data.values[index])
      
      return this.legendOptions.reversed ? legend.reverse() : legend
    },
    isLegend() {
      return !(this.options.legendPosition === 'none')
    },
    legendStyles() {
      const legendPaddings = {
        right: `padding-top: ${this.options.margin.top}px;`,
        left: `padding-top: ${this.options.margin.top}px;`,
        bottom: `padding-left: ${this.options.margin.left}px; padding-top: ${this.options.margin.top}px`,
        top: `padding-left: ${this.options.margin.left}px;`,
      }
      return legendPaddings[this.options.legendPosition]
    },
    animationDuration() {
      return this.options.animationDuration ? this.options.animationDuration : 500
    },
    color() {
      return scaleOrdinal(schemeCategory10)
    },
  },
  methods: {
    fixedColor(i) {
      const { color, otherId } = this
      return this.data.itemsId[i] === otherId ? color(8): color(i)
    },
  }
}
</script>
