<template>
  <v-chip v-if="condition" class="mr-4 vc" close @click:close="callback">
    <span :style="minWidthStyle">{{ text }}</span>
  </v-chip>
</template>

<script>
export default {
  props: {
    condition: {
      type: Boolean,
      default: false,
    },
    callback: {
      type: Function,
      default: undefined,
    },
    text: {
      type: String,
      default: 'Парметр не задан',
    },
    tooltipText: {
      type: String,
      default: 'Сбросить',
    },
    textMinWidth: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    minWidthStyle() {
      return `min-width: ${this.textMinWidth}px`
    },
  },
}
</script>
