<template>
  <dashboard-card
    title="Демографические группы"
    subtitle="Потерянные годы по демографическим группам"
    buttonText="Аналитика"
    :to="'reasons'"
  >
    <div class="d-flex justify-space-around">
      <demographic-sex
        v-if="dataSex"
        :data="dataSex"
        :selectedItems="selectedSex"
        :onClick="onSexClick"
      />
      <v-skeleton-loader v-else width="220" type="image"></v-skeleton-loader>
      <demographic-population
        v-if="dataPopulation"
        :data="dataPopulation"
        :selectedItems="selectedPopulation"
        :onClick="onPopulationClick"
      />
      <v-skeleton-loader v-else width="220" type="image"></v-skeleton-loader>
    </div>
  </dashboard-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import DashboardCard from '../layout/DashboardCard'
import dataLoaderMixin from './loadDataForDasbordCard'
import DemographicSex from './DemographicSex'
import DemographicPopulation from './DemographicPopulation'

export default {
  mixins: [dataLoaderMixin],
  data: () => ({
    data: [],
    loadParams: {
      id: '2',
      type: 'pies',
      options: { reason: 306, region: 94, populationGroup: 3, year: 2019 },
    },
    loaded: false,
  }),
  components: {
    DashboardCard,
    DemographicSex,
    DemographicPopulation,
  },
  methods: {
    ...mapActions({ setParams: 'dbd/setParams', setParam: 'dbd/setParam' }),
    onSexClick(a, b) {
      let param
      if (this.getDashboard.bySex[b].sexId !== (this.selectedSex[0] || 3)) {
        param = this.getDashboard.bySex[b].sexId
      } else {
        param = 3
      }
      this.setParam({ sexId: param })
    },
    onPopulationClick(a, b) {
      let currentPopulation = this.getParams.populationGroupId
      let needPopulation = this.getDashboard?.byPopulation[b]?.populationGroupId
      if (needPopulation === currentPopulation) {
        this.setParams({ ...this.getParams, populationGroupId: 3 })
      } else {
        this.setParams({
          ...this.getParams,
          populationGroupId: needPopulation,
        })
      }
    },
  },
  computed: {
    ...mapGetters({ getDashboard: 'dbd/getGoodData', getParams: 'dbd/getParams' }),
    dataSex() {
      if (!this.getDashboard.bySex) {
        return null
      }
      let rawData = this.getDashboard.bySex
      const names = []
      const values = []
      const itemsId = []
      rawData
        .filter(d => d.sexId !== 3)
        .forEach(d => {
          names.push(d.name)
          values.push(parseFloat(d.value))
          itemsId.push(d.sexId)
        })
      return { names, values, itemsId }
    },
    selectedSex() {
      if (!this.getParams.sexId) {
        return []
      }
      if (this.getParams.sexId == 1) {
        return [1]
      } else if (this.getParams.sexId == 2) {
        return [2]
      } else {
        return []
      }
    },
    selectedPopulation() {
      if (!this.getParams.populationGroupId) {
        return []
      }
      if (this.getParams.populationGroupId == 1) {
        return [1]
      } else if (this.getParams.populationGroupId == 2) {
        return [2]
      } else {
        return []
      }
    },
    dataPopulation() {
      if (!this.getDashboard.byPopulation) {
        return null
      }
      let rawData = this.getDashboard.byPopulation
      const names = []
      const values = []
      const itemsId = []
      rawData
        .filter(d => d.populationGroupId !== 3)
        .forEach(d => {
          names.push(d.comment)
          values.push(parseFloat(d.value))
          itemsId.push(d.populationGroupId)
        })
      return { names, values, itemsId }
    },
  },
}
</script>

<style scoped>
.v-skeleton-loader >>> .v-skeleton-loader__image {
  height: 230px !important;
}
</style>
