<template>
  <div class="chart-plot" :style="containerStyle">
    <SvgSizeTemplate :width="width" :height="height" :margin="margin" />
  </div>
</template>

<script>
import SvgSizeTemplate from './SvgSizeTemplate'
import d3PlotMixin from './d3PlotMixin'
import d3WatchAndMountMixin from './d3WatchAndMountMixin'

import { select, scaleBand, scaleLinear, axisBottom, max, format, event as d3event } from 'd3'

export default {
  mixins: [d3PlotMixin, d3WatchAndMountMixin],
  components: {
    SvgSizeTemplate,
  },
  props: {
    height: {
      type: Number,
      default: 230,
    },
    width: {
      type: Number,
      default: 450,
    },
    data: {
      type: Object,
      required: true,
    },
    options: {
      type: Object,
      required: true,
    },
    events: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      textMargin: 10,
      margin: {
        left: 20,
        top: 30,
        right: 20,
        bottom: 20,
      },
      lastValues: [],
    }
  },
  created() {
    this.lastValues = [...new Array(this.data.values.length)].map(() => 0)
  },
  computed: {
    containerStyle() {
      return `height: ${this.height}px;width: ${this.width}px;`
    },
    points() {
      return this.data.years.map((d, i) => ({
        x: this.data.years[i],
        y: this.data.values[i],
        active: this.options.activeIndex === i,
      }))
    },
    color() {
      return this.options.color ? this.options.color : 'steelblue'
    },
    activeColor() {
      return this.options.activeColor ? this.options.activeColor : 'firebrick'
    },
    format() {
      const { valueType = 'decimal' } = this.options
      if (valueType === 'decimal') {
        return format('.2~f')
      } else if (valueType === 'integer') {
        return a => parseInt(a)
      } else if (valueType === 'percent') {
        return format('.2~%')
      } else {
        throw Error('Заданный формат преобразования не поддерживается')
      }
    },
    xScale() {
      return scaleBand()
        .domain(this.points.map(d => d.x))
        .range([0, this.mainPlotAreaWidth])
        .paddingInner(0.45)
        .paddingOuter(0.1)
    },
    yScale() {
      return scaleLinear()
        .domain([0, max(this.points.map(d => d.y)) || 1])
        .range([this.mainPlotAreaHeight, 0])
    },
  },
  methods: {
    render() {
      this.renderContent()
      this.renderXAxis()
    },
    renderXAxis() {
      const { svg, animationDuration, xScale } = this
      svg
        .select('g.x-axis-bottom')
        .transition('axisAnimation')
        .duration(animationDuration)
        .call(axisBottom().scale(xScale))
    },
    renderContent() {
      const { points, svg, xScale, lastValues } = this
      svg
        .select('g.content')
        .selectAll('g.bar')
        .each((d, i) => (lastValues[i] = d.y))
        .data(points)
        .join(
          enter =>
            enter
              .append('g')
              .classed('bar', true)
              .attr('transform', ({ x }) => `translate(${xScale(x)},0)`)
              .call(this.rectPrepare)
              .call(this.rectAnimation),
          update => update.call(this.rectAnimation)
        )
    },
    changeOpacity(element, opacity) {
      element
        .transition('changeOpacity')
        .duration(this.animationDuration)
        .attr('fill-opacity', opacity)
    },
    bindEvents(element, events) {
      if (typeof events !== 'object') return
      Object.keys(events).forEach(e => {
        element.on(e, events[e])
      })
    },
    rectPrepare(s) {
      const { mainPlotAreaHeight, xScale } = this
      s.append('rect')
        .attr('x', 0)
        .attr('y', mainPlotAreaHeight)
        .attr('fill-opacity', 0.3)
        .style('stroke-width', 0.8)
        .attr('width', xScale.bandwidth())
        .call(this.bindEvents, this.events)
        .on('mouseover.internal', () => select(d3event.target).call(this.changeOpacity, 0.5))
        .on('mouseout.internal', () => select(d3event.target).call(this.changeOpacity, 0.3))
      s.append('text')
        .attr('x', xScale.bandwidth() / 2)
        .attr('y', mainPlotAreaHeight)
        .attr('text-anchor', 'middle')
        .attr('font-weight', '450')
        .attr('fill', 'grey')
    },
    rectAnimation(s) {
      const {
        animationDuration,
        activeColor,
        color,
        mainPlotAreaHeight,
        format,
        yScale,
        lastValues,
        textMargin,
      } = this
      s.select('g.bar rect')
        .transition('rectAnimation')
        .duration(animationDuration)
        .attr('y', d => yScale(d.y))
        .attr('height', d => mainPlotAreaHeight - yScale(d.y))
        .attr('fill', ({ active }) => (active ? activeColor : color))
        .style('stroke', ({ active }) => (active ? activeColor : color))
      s.select('g.bar text')
        .transition('textAnimation')
        .duration(animationDuration)
        .attr('y', d => yScale(d.y) - textMargin)
        .textTween((d, i) => t => {
          return format(lastValues[i] + (d.y - lastValues[i]) * t)
        })
    },
  },
}
</script>

<style scoped>
svg >>> rect {
  cursor: pointer;
}
svg >>> .xAxis {
  stroke-width: 0.5;
}
svg >>> .xAxis path {
  color: grey;
}
</style>
