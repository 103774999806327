import { mapGetters, mapActions } from "vuex";

export default {
  methods: {
    ...mapActions(["loadPlotData"]),
    loadPlotDataMixin(params) {
      this.loadPlotData(params).then((r) => {
        this.data = r.data
        this.loaded = true // Убрать когда график научится не падать на пустых данных
      });
    },
  },
  // Зачем этот метод?
  computed: {
    ...mapGetters(["getPlotData"]),
  }
}
