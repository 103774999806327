<template>
  <dashboard-card
    title="Причины смерти"
    subtitle="Распределение потерянных лет по основным причинам"
    buttonText="Анализ по параметрам"
    :to="'reasons'"
  >
    <pie-with-legend
      v-if="computedDataForSimplePieChart"
      :data="computedDataForSimplePieChart"
      :options="options"
      :events="events"
      :legendOptions="legendOptions"
      :width="250"
      :height="230"
    />
    <v-skeleton-loader v-else class="mx-auto" width="440" type="image"></v-skeleton-loader>
  </dashboard-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import DashboardCard from '../layout/DashboardCard'
import PieWithLegend from '../../d3new/PieWithLegend'

export default {
  data() {
    return {
      legendOptions: {
        colCount: 1,
        opacity: 0.3,
      },
      optionsTemplate: {
        selectedIds: [],
        valueType: 'decimal',
        decimalDigits: 2,
        animationDuration: 500,
        legendPosition: 'right', //left, right, top, bottom, none
        /*
          Это расстояния от границ SVG внутрь до границ области графика.
          Логичнее назвать это padding, но документация и примеры к D3 называют это margin.
          Для единообразия используем margin.
          Для графика-пирога отступ внутри SVG нужен, чтобы не обрезало сектора при анимации-расширении.
        */
        margin: {
          left: 20,
          top: 12,
          right: 20,
          bottom: 12,
        },
      },
      events: {
        click: e => {
          const reasons = [...this.getDashboardParams.reasons]

          const index = reasons.findIndex(i => i === e.itemId)
          if (index === -1) {
            reasons.push(e.itemId)
          } else {
            reasons.splice(index, 1)
          }
          this.setDashboardParams({
            ...this.getDashboardParams,
            reasons,
          })
        },
      },
    }
  },
  components: {
    DashboardCard,
    PieWithLegend,
  },
  methods: {
    ...mapActions({
      setDashboardParams: 'dbd/setParams',
    }),
  },
  created() {
    this.options.selectedIds = this.getDashboardParams.reasons
  },
  computed: {
    ...mapGetters({
      getDashboard: 'dbd/getGoodData',
      getDashboardParams: 'dbd/getParams',
    }),
    computedDataForSimplePieChart() {
      if (!this.getDashboard.byReasons?.length) return false
      const names = []
      const values = []
      const itemsId = []
      this.getDashboard.byReasons?.forEach(d => {
        names.push(d.name)
        values.push(+d.value)
        itemsId.push(d.id)
      })
      return { names, values, itemsId }
    },
    options() {
      return { ...this.optionsTemplate, selectedIds: this.getDashboardParams.reasons }
    },
  },
}
</script>

<style scoped>
.v-skeleton-loader >>> .v-skeleton-loader__image {
  height: 230px !important;
}
</style>
