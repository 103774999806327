<template>
  <v-main>
    <v-container class="pt-1 pb-0">
      <v-row class="mt-1 mb-1 pl-4 align-cente" style="min-height: 48px">
        <filter-bar />
      </v-row>

      <v-row class="mb-1 mt-1 justify-space-between">
        <small-card>
          <reasons-card />
        </small-card>

        <small-card>
          <demographic-card />
        </small-card>

        <small-card>
          <dynamic-card />
        </small-card>
      </v-row>

      <v-row class="justify-space-between">
        <large-card>
          <ages-card />
        </large-card>

        <large-card>
          <regions-card />
        </large-card>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import SmallCard from '../layout/SmallCard'
import LargeCard from '../layout/LargeCard'

import DynamicCard from '../dashboard/DynamicCard'
import DemographicCard from '../dashboard/DemographicCard'
import ReasonsCard from '../dashboard/ReasonsCard.vue'
import AgesCard from '../dashboard/AgesCard.vue'
import RegionsCard from '../dashboard/RegionsCard.vue'

import FilterBar from '../dashboard/FilterBar'

import { mapActions } from 'vuex'

export default {
  components: {
    DynamicCard,
    SmallCard,
    LargeCard,
    DemographicCard,
    ReasonsCard,
    AgesCard,
    RegionsCard,
    FilterBar,
  },
  created() {
    this.loadDashbord()
  },
  methods: {
    ...mapActions({
      loadDashbord: 'dbd/loadData',
    }),
  },
}
</script>
