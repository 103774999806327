<template>
  <dashboard-card
    title="Возрастные группы"
    subtitle="Возрастной профиль потерь по классам причин смерти"
    buttonText="Больше информации"
    :to="'by-age'"
    :contentMinHeight="350"
  >
    <multiple-histograms
      v-if="getDashboardData.byAges"
      :data="dataForStackedBars"
      :options="options"
    />
    <v-skeleton-loader v-else class="mx-auto" :width="694" type="image"></v-skeleton-loader>
  </dashboard-card>
</template>

<script>
import { mapGetters } from 'vuex'

import MultipleHistograms from '../../d3new/MultipleHistograms.vue'
import DashboardCard from '../layout/DashboardCard'

import { schemeCategory10 } from 'd3'

export default {
  data() {
    return {
      tmpData: false,
      options: {
        selectedIndexes: [],
      },
    }
  },
  components: {
    DashboardCard,
    MultipleHistograms,
  },
  computed: {
    ...mapGetters({
      getDashboardData: 'dbd/getGoodData',
      getDashboardParams: 'dbd/getGoodParams',
      getDisplayedReasons: 'dbd/getDisplayedReasons',
    }),
    selectedReasons() {
      const selectedReasons = this.getDisplayedReasons.filter(d =>
        this.getDashboardData?.params?.reasons.includes(d)
      )
      return selectedReasons?.length ? selectedReasons : this.getDisplayedReasons
    },
    dataForStackedBars() {
      const rows = []
      this.selectedReasons.forEach(reason => {
        const filtered = this.getDashboardData.byAges
          .filter(d => d.reasonId === reason)
          .sort((a, b) => a.ageGroupId - b.ageGroupId)
        
        const reasonTemplate = filtered[0]
        let isNotEmpty = false
        const values = []

        for (let i = 0; i < filtered.length; i ++ ) {
          const v = filtered.find(d => d.ageGroupId === i + 1) ?? {
              ...reasonTemplate,
              ageGroupId: i + 1,
              ageGroupName: this.getUniqueAgeGroupNames[i],
              value: 0,
            }
          values.push(v)
          if (!isNotEmpty && v.value > 0) isNotEmpty = true
        }
                
        if (isNotEmpty) {
          rows.push({
            reasonName: reasonTemplate.reasonName,
            reasonId: reason,
            values: values,
            color: this.getColorByReasonId(reason),
          })
        }
      })

      return {
        rows,
        ages: this.getUniqueAgeGroupNames,
      }
    },
    getUniqueAgeGroupNames() {
      const uniqueAgeGroupNames = new Set()
      this.getDashboardData.byAges
        .slice()
        .sort((a, b) => a.ageGroupId - b.ageGroupId)
        .forEach(d => uniqueAgeGroupNames.add(d.ageGroupName))
      return Array.from(uniqueAgeGroupNames)
    },
  },
  methods: {
    getColorByReasonId(id) {
      return schemeCategory10[this.getDisplayedReasons.findIndex(i => i === id)]
    },
  },
}
</script>

<style scoped>
.v-skeleton-loader >>> .v-skeleton-loader__image {
  height: 350px !important;
}
</style>
