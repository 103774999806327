<template>
  <div class="d-flex align-center">
    <div v-if="numOfFilters">
      <FilterChip :condition="isReasonSelected" :text="reasonsChipText" :callback="onResetReason" />
      <FilterChip
        :condition="isSexSelected"
        :text="sexChipText"
        :callback="onResetSex"
        :textMinWidth="62"
      />
      <FilterChip
        :condition="isPopulationGroupSelected"
        :text="populationGroupChipText"
        :callback="onResetPopulationGroup"
        :textMinWidth="79"
      />
      <FilterChip :condition="isYearSelected" :text="currentYearText" :callback="onResetYear" />
      <!-- Регион похож на причину. Остальные похожи между собой -->
      <FilterChip :condition="isRegionSelected" :text="regionChipText" :callback="onResetRegion" />
      <FilterChip :condition="isMoreThenOneFilter" text="Сбросить все" :callback="onResetAll" />
      <span v-if="isNoFilters" class="mr-4 font-weight-bold grey--text">не выбраны</span>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import FilterChip from './FilterChip'

export default {
  /*
      В default не удается зашить пустые массивы [] для reasons и regions
      Почему-то они не отрабатывают
      Приходится каждый раз явно там где это нужно вызывать new Array(0)
    */
  data: () => ({}),
  components: {
    FilterChip,
  },
  created() {
    this.DEFAULT.year = this.lastYear
  },
  computed: {
    ...mapGetters({
      getDashboardParams: 'dbd/getParams',
      getDashboardData: 'dbd/getData',
      getDictionaries: 'ly2/getDictionaries',
    }),
    DEFAULT() {
      return {
        year: this.lastYear,
        sexId: 3,
        populationGroupId: 3,
      }
    },
    /*
        Складываю логические типы. В JS это работает
        Но не нужно ли переделать на Number(this.isSmth...) или на тернарный (isSmth...) ? 1 : 0
      */
    numOfFilters() {
      return (
        this.isReasonSelected +
        this.isSexSelected +
        this.isPopulationGroupSelected +
        this.isYearSelected +
        this.isRegionSelected
      )
    },
    isNoFilters() {
      return this.numOfFilters === 0
    },
    isAnyFilter() {
      return this.numOfFilters > 0
    },
    isMoreThenOneFilter() {
      return this.numOfFilters > 1
    },
    lastYear() {
      const years = this.getDictionaries?.years
      return years?.[years.length - 1]?.value || new Date().getFullYear()
    },
    currentYearText() {
      return this.getDashboardParams.year ? this.getDashboardParams.year.toString() : ''
    },
    isReasonSelected() {
      return Boolean(this.getDashboardParams.reasons.length)
    },
    isRegionSelected() {
      return Boolean(this.getDashboardParams.regions.length)
    },
    isSexSelected() {
      return this.getDashboardParams.sexId !== this.DEFAULT.sexId
    },
    isPopulationGroupSelected() {
      return this.getDashboardParams.populationGroupId !== this.DEFAULT.populationGroupId
    },
    isYearSelected() {
      return this.getDashboardParams.year !== this.DEFAULT.year
    },
    reasonsChipText() {
      if (
        this.getDashboardParams.reasons.length === 1 &&
        this.getDashboardData.byReasons?.length > 0
      ) {
        return (
          this.getDashboardData.byReasons.find(d => d.id === this.getDashboardParams.reasons[0])
            ?.name || 'Другие причины'
        )
      }
      return `Причин выбрано: ${this.getDashboardParams.reasons.length}`
    },
    regionChipText() {
      if (
        this.getDashboardParams.regions.length === 1 &&
        this.getDashboardData.byRegions?.length > 0
      ) {
        return this.getDashboardData.byRegions.find(
          d => d.regionId === this.getDashboardParams.regions[0]
        )?.name
      }
      return `Регионы: ${this.getDashboardParams.regions.length}`
    },
    /*
        dbd/getParams — загружается быстро
        ly/getDictionaries — медленно, поэтому их приходистя проверять
      */
    sexChipText() {
      if (!this.getDictionaries?.sex.length) return 'Пол'
      return (
        `Пол: ` +
        this.getDictionaries.sex
          .find(o => o.id === this.getDashboardParams.sexId)
          .name.toLowerCase()
      )
    },
    populationGroupChipText() {
      if (!this.getDictionaries?.populationGroup.length) return 'Тип населения'
      return (
        'Население: ' +
        this.getDictionaries.populationGroup
          .find(o => o.id === this.getDashboardParams.populationGroupId)
          .comment.toLowerCase()
      )
    },
  },
  methods: {
    ...mapActions({
      setDashboardParams: 'dbd/setParams',
    }),
    onResetAll() {
      console.log(this.DEFAULT)
      this.setDashboardParams({
        ...this.getDashboardParams,
        ...this.DEFAULT,
        reasons: new Array(0),
        regions: new Array(0),
      })
    },
    onResetReason() {
      this.setDashboardParams({
        ...this.getDashboardParams,
        reasons: new Array(0),
      })
    },
    onResetRegion() {
      this.setDashboardParams({
        ...this.getDashboardParams,
        regions: new Array(0),
      })
    },
    onResetSex() {
      this.setDashboardParams({
        ...this.getDashboardParams,
        sexId: this.DEFAULT.sexId,
      })
    },
    onResetPopulationGroup() {
      this.setDashboardParams({
        ...this.getDashboardParams,
        populationGroupId: this.DEFAULT.populationGroupId,
      })
    },
    onResetYear() {
      this.setDashboardParams({
        ...this.getDashboardParams,
        year: this.DEFAULT.year,
      })
    },
  },
}
</script>
