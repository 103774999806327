<template>
  <dashboard-card
    title="Регионы"
    subtitle="Анализ потерянных лет по регионам"
    buttonText="Все причины"
    :contentMinHeight="350"
    :to="'regions'"
  >
    <regions-map
      v-if="dashboardData.byRegions"
      :data="regions"
      :options="options"
      :events="events"
      @map-hover="onMapHover"
    />
    <v-skeleton-loader v-else class="mx-auto" :width="694" type="image"></v-skeleton-loader>
  </dashboard-card>
</template>

<script>
import DashboardCard from '../layout/DashboardCard'
import RegionsMap from '../../d3new/RegionsMap'
import { mapGetters, mapActions } from 'vuex'

/*
  Поскольку есть версия карты которая работает с внешней легендой
  То ховером карты управляет внешний компонент
  Даже в том случае когда это как будто не нужно
*/

export default {
  data() {
    return {
      optionsTemplate: {
        scale: 0.7,
        width: 706,
        height: 350,
        opacity: {
          default: 0.65,
          hover: 1,
          selected: 0.85,
          otherOnHover: 0.65,
        },
        strokeColor: 'rgb(251, 167, 96)',
        selectedIds: [],
        hoveredId: null,
      },
      events: {
        click: e => {
          const selectedRegions = [...this.getDashboardParams.regions]
          const regionId = e.regionId
          const index = selectedRegions.findIndex(i => i === regionId)

          if (index === -1) {
            selectedRegions.length = 0
            selectedRegions.push(regionId)
          } else {
            selectedRegions.splice(index, 1)
          }
          /*
            Сначала устанавливаю новое значение в хранилище
            Потом беру для компонент актуальное значение из хранилища
            loadDsahboardData после замены параметров приходится дергать вручную. Это метод и он сам не отрабатывает
          */
          this.setDashboardParams({
            ...this.getDashboardParams,
            regions: selectedRegions,
          })
          //          this.options.selectedIds = selectedRegions
        },
      },
      hoveredRegion: null,
    }
  },
  components: {
    DashboardCard,
    RegionsMap,
  },
  computed: {
    ...mapGetters({
      dashboardData: 'dbd/getGoodData',
      getDashboardParams: 'dbd/getParams',
    }),
    options() {
      return {
        ...this.optionsTemplate,
        selectedIds: this.regionIds,
        hoveredId: this.hoveredRegion,
      }
    },
    regionIds() {
      return this.getDashboardParams.regions ?? []
    },
    regions() {
      if (this.dashboardData.regions) {
        return this.dashboardData.regions.map(d => {
          const r = this.dashboardData.byRegions.find(b => b.regionId === d.id)
          if (r) {
            return { ...d, regionId: d.id, value: +r.value }
          } else {
            return { ...d, regionId: d.id, value: 0 }
          }
        })
      } else {
        return []
      }
    },
  },
  methods: {
    ...mapActions({
      setDashboardParams: 'dbd/setParams',
    }),
    onMapHover(regionId) {
      this.hoveredRegion = regionId
    },
  },
}
</script>

<style scoped>
.v-skeleton-loader >>> .v-skeleton-loader__image {
  height: 350px !important;
}
</style>
