<template>
  <div class="charts-legend">
    <div
      :class="colCount ? 'legend-items-columns ' : 'legend-items'"
      :style="`--duration:${animationDuration}s`"
    >
      <transition-group name="legend-items-list" tag="div">
        <div
          :class="`legend-item item-${item.id}`"
          v-for="item of items"
          :key="item.id"
          :ref="'legend-' + item.id"
          :style="`--col-count:${colCount}`"
          @mouseover="$emit('input', item.id)"
          @mouseout="$emit('input', null)"
        >
          <div
            class="legend-swatch-border"
            :style="`--opacity:${opacity}; border-color: ${item.color}`"
          >
            <div
              class="legend-swatch"
              :style="`--opacity:${opacity}; background: ${item.color}; border-color: ${item.color}`"
            ></div>
          </div>
          <div class="legend-label" :title="item.name">
            {{ item.name }}
          </div>
        </div>
      </transition-group>
    </div>
  </div>
</template>

<script>
export default {
  name: "ChartLegend",
  props: {
    // Массив объектов {id, name, color}
    items: {
      type: Array,
      default: () => [],
    },
    events: {
      type: Object,
      default: () => {},
    },
    animationDuration: {
      type: Number,
      default: 500,
    },
    options: {
      type: Object,
      default: () => ({
        colCount: null,
        opacity: 1,
      }),
    },
    active: {
      type: Number,
      default: null,
    },
  },
  computed: {
    colCount() {
      return this.options.colCount ? this.options.colCount : 0;
    },
    opacity() {
      return this.options.opacity;
    },
  },
  watch: {
    items: {
      handler() {
        this.$nextTick(() => {
          for (const i of this.items) {
            if (this.$refs["legend-" + i.id] && this.$refs["legend-" + i.id][0]) {
              for (const e in this.events) {
                const f = (event) => {
                  this.events[e](event, i);
                };
                this.$refs["legend-" + i.id][0].removeEventListener(e, f);
                this.$refs["legend-" + i.id][0].addEventListener(e, f);
              }
            }
          }
        });
      },
      immediate: true,
    },
    active: {
      handler() {
        const items = this.items;
        this.$nextTick(() => {
          items.forEach(({ id }) => {
            this.active === null || id === this.active
              ? this.$refs["legend-" + id][0].classList.remove("cloudy")
              : this.$refs["legend-" + id][0].classList.add("cloudy");
          });
        });
      },
      immediate: true,
    },
  },
};
</script>

<style scoped>
.charts-legend {
  cursor: default;
}

.legend-item {
  display: flex;
  overflow: hidden;
  align-items: center;
}

.legend-label {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  flex-grow: 1;
  margin: 2px 6px 2px 2px;
}

.legend-swatch-border {
  width: 30px;
  min-width: 30px;
  height: 10px;
  margin: 6px;
  border: 1px solid;
}

.legend-swatch {
  width: 28px;
  min-width: 28px;
  height: 8px;
  opacity: var(--opacity);
}

.legend-items > div {
  font-size: 12px;
  display: flex;
  flex-wrap: wrap;
}

.legend-items-columns > div {
  font-size: 12px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.legend-items-columns > div .legend-item {
  width: calc(100% / var(--col-count));
  min-width: 150px;
}

.legend-item {
  transition: opacity calc(var(--duration) / 1000);
}

.legend-items-list-enter,
.legend-items-list-leave-to {
  opacity: 0;
}

.cloudy {
  opacity: 0.7;
}
</style>
