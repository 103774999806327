<template>
  <dashboard-card
    title="Динамика"
    subtitle="Динамика потерянных лет жизни"
    buttonText="Прогноз"
    :to="'dynamic'"
  >
    <bar-chart-simple
      v-if="computedDataForBarChartSimple"
      :data="computedDataForBarChartSimple"
      :options="options"
      :events="events"
    />
    <v-skeleton-loader v-else class="mx-auto" width="440" type="image"></v-skeleton-loader>
  </dashboard-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import DashboardCard from '../layout/DashboardCard'
import BarChartSimple from '../../d3new/BarChartSimple'

export default {
  data() {
    return {
      options: {
        activeIndex: null,
        valueType: 'decimal',
        activeColor: 'chocolate',
      },
      events: {
        click: e => {
          /* Значение года в событии d3 на этом графике лежит в x-координате */
          const year = e.x
          const yearIndex = this.getYears.findIndex(y => y === year)
          this.options.activeIndex = yearIndex
          this.setDashboardParams({
            ...this.getDashboardParams,
            year,
          })
        },
      },
    }
  },
  components: {
    DashboardCard,
    BarChartSimple,
  },
  watch: {
    getYears() {
      this.options.activeIndex = this.getYears.findIndex(y => y === this.getDashboardParams.year)
    },
  },
  methods: {
    ...mapActions({
      setDashboardParams: 'dbd/setParams',
      //      loadDsahboardData: 'dbd/loadData',
    }),
  },
  created() {
    this.options.activeIndex = this.getYears.findIndex(y => y === this.getDashboardParams.year)
  },
  computed: {
    ...mapGetters({
      getDashboard: 'dbd/getData',
      getDashboardParams: 'dbd/getParams',
    }),
    getYears() {
      if (!this.getDashboard.byYears) {
        return []
      }
      return this.getDashboard.byYears.map(d => d.year)
    },
    computedDataForBarChartSimple() {
      if (!this.getDashboard.byYears) {
        return null
      }
      const years = []
      const values = []
      this.getDashboard.byYears.forEach(d => {
        years.push(d.year)
        values.push(parseFloat(d.value))
      })
      return { years, values }
    },
  },
}
</script>

<style scoped>
.v-skeleton-loader >>> .v-skeleton-loader__image {
  height: 230px !important;
}
</style>
