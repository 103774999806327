import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{staticClass:"mb-2 mx-0"},[_c(VCardTitle,{staticClass:"pt-1"},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c(VCardSubtitle,{staticClass:"mb-0 pb-0"},[_vm._v(" "+_vm._s(_vm.subtitle)+" ")]),_c(VCardText,{staticClass:"pa-0",style:(_vm.style)},[_vm._t("default")],2),_c(VCardActions,[(_vm.buttonCallback)?_c(VBtn,{attrs:{"text":"","color":"primary"},on:{"click":_vm.buttonCallback}},[_vm._v(" "+_vm._s(_vm.buttonText)+" ")]):_c(VBtn,{attrs:{"text":"","color":"primary","to":_vm.to}},[_vm._v(" "+_vm._s(_vm.buttonText)+" ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }