<template>
  <div>
    <TopMenu />

    <DashbordContent />
  </div>
</template>

<script>
import TopMenu from '../components/layout/TopMenu'
import DashbordContent from '../components/content/DashbordContent'

export default {
  name: 'DashBord',
  components: {
    TopMenu,
    DashbordContent,
  },
}
</script>
