<template>
  <v-card class="mb-2 mx-0">
    <v-card-title class="pt-1"> {{ title }} </v-card-title>
    <v-card-subtitle class="mb-0 pb-0"> {{ subtitle }} </v-card-subtitle>
    <!-- todo: не передавать в графики горизональные отступы  -->
    <v-card-text class="pa-0" :style="style">
      <slot></slot>
    </v-card-text>
    <v-card-actions>
      <v-btn v-if="buttonCallback" text color="primary" @click="buttonCallback">
        {{ buttonText }}
      </v-btn>
      <v-btn v-else text color="primary" :to="to"> {{ buttonText }} </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: 'Заголовок',
    },
    subtitle: {
      type: String,
      default: 'Заголовок',
    },
    buttonText: {
      type: String,
      default: 'Заголовок',
    },
    buttonCallback: {
      type: Function,
      default: null,
    },
    contentMinHeight: {
      type: Number,
      default: 230,
    },
    to: {
      type: String,
    },
  },
  computed: {
    style() {
      return `min-height: ${this.contentMinHeight}px`
    },
  },
}
</script>
