<template>
  <div>
    <pie-with-legend
      :data="calcData"
      :options="options"
      :events="events"
      :height="194"
      :width="230"
      :legendOptions="legendOptions"
    />
  </div>
</template>

<script>
import PieWithLegend from '../../d3new/PieWithLegend'

export default {
  props: ['data', 'selectedItems', 'onClick'],
  components: {
    PieWithLegend,
  },
  data() {
    return {
      optionsTemplate: {
        selectedIds: [],
        valueType: 'percent',
        decimalDigits: 0,
        legendPosition: 'bottom', //left, right, top, bottom, none
        /*
          Это расстояние от границ SVG внутрь до границ области графика.
          Логичнее назвать это padding, но документация и примеры к D3 называют это margin.
          Для единообразия используем margin.
          Для графика-пирога отступ внутри SVG нуженб чтобы не обрезало сектора при анимации-расширении.
        */
        margin: {
          left: 20,
          top: 10,
          right: 10,
          bottom: 10,
        },
      },
      legendOptions: {
        colCount: null,
        opacity: 0.3,
        reversed: true,
      },
      events: {
        click: (a, b) => {
          if (this.onClick) {
            this.onClick(a, b)
          }
        },
      },
    }
  },
  computed: {
    options() {
      return { ...this.optionsTemplate, selectedIds: this.selectedItems }
    },
    calcData() {
      return { ...this.data, titlePrefix: 'Пол' }
    },
  },
}
</script>
