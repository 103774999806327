<template>
  <div class="chart-plot" :style="containerStyleHeight">
    <SvgSizeTemplate :width="width" :height="height" :margin="margin" />
  </div>
</template>

<script>
import { scaleBand, scaleLinear, axisBottom, axisRight, max, format } from 'd3'
import { mapActions } from 'vuex'

import SvgSizeTemplate from './SvgSizeTemplate.vue'
import d3PlotMixin from './d3PlotMixin'
import d3WatchAndMountMixin from './d3WatchAndMountMixin'

export default {
  components: { SvgSizeTemplate },
  mixins: [d3PlotMixin, d3WatchAndMountMixin],
  props: {
    height: {
      type: Number,
      default: 350,
    },
    width: {
      type: Number,
      default: 706,
    },
    data: {
      type: Object,
      required: true,
    },
    options: {
      type: Object,
      required: true,
    },
    events: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      margin: {
        left: 20,
        top: 25,
        right: 150,
        bottom: 40,
      },
    }
  },
  computed: {
    containerStyleHeight() {
      return `height: ${this.height}px;`
    },
    xScale() {
      return scaleBand()
        .domain(this.data.ages)
        .rangeRound([0, this.mainPlotAreaWidth])
        .paddingInner(0.1)
    },
    yScales() {
      const result = {}
      this.data.rows.forEach(row => {
        result[row.reasonName] = scaleLinear()
          .domain([0, max(row.values.map(v => v.value)) || 1])
          .range([0, this.yScaleAllReasons.bandwidth()])
      })
      return result
    },
    yScaleAllReasons() {
      return scaleBand()
        .domain(this.data.rows.map(d => d.reasonName))
        .range([0, this.mainPlotAreaHeight])
        .round(true)
        .paddingInner(0.2)
        .align(1)
    },
    yScaleAllReasons2() {
      return scaleBand()
        .domain(this.data.rows.map(d => d.reasonName))
        .range([0, this.mainPlotAreaHeight])
        .round(true)
        .paddingInner(1)
        .paddingOuter(0.4)
        .align(1)
    },
  },
  methods: {
    render() {
      if (!this.data.rows.length) return
      this.createContent()
      this.createYAxis()
      this.createXAxis()
    },
    createContent() {
      const { svg, data, enterGroup, updateGroup, removeGroup } = this
      svg
        .select('g.content')
        .selectAll('g.reason')
        .data(data.rows, d => d.reasonId)
        .join(enterGroup, updateGroup, removeGroup)
    },
    enterGroup(enter) {
      const { enterRect, yScaleAllReasons } = this
      enter
        .append('g')
        .classed('reason', true)
        .attr('transform', d => `translate(0, ${yScaleAllReasons(d.reasonName)})`)
        .attr('fill', d => d.color)
        .attr('stroke', d => d.color)
        .style('stroke-width', 0.8)
        .selectAll('g.reason rect')
        .data(d => d.values)
        .join(enterRect)
    },
    updateGroup(update) {
      const { enterRect, updateRect, yScaleAllReasons, animationDuration } = this
      update
        .attr('fill', d => d.color)
        .attr('stroke', d => d.color)
        .call(enter =>
          enter
            .transition()
            .duration(animationDuration)
            .attr('transform', d => `translate(0, ${yScaleAllReasons(d.reasonName)})`)
        )
        .selectAll('g.reason rect')
        .data(d => d.values)
        .join(enterRect, updateRect)
    },
    removeGroup(exit) {
      const { yScaleAllReasons, animationDuration } = this
      exit
        .selectAll('rect')
        .transition()
        .duration(animationDuration)
        .attr('height', 0)
        .attr('y', yScaleAllReasons.bandwidth())
        .remove()
    },
    enterRect(enter) {
      const { xScale, updateRect, yScaleAllReasons } = this
      enter
        .append('rect')
        .attr('x', d => xScale(d.ageGroupName))
        .attr('width', xScale.bandwidth())
        .attr('height', 0)
        .attr('fill-opacity', 0.3)
        .attr('y', yScaleAllReasons.bandwidth())
        .call(updateRect)
    },
    updateRect(update) {
      const { yScales, xScale, yScaleAllReasons, setTooltip } = this
      update
        .attr('x', d => xScale(d.ageGroupName))
        .on('mouseover', function (d) {
          const clientRect = this.getBoundingClientRect()
          setTooltip({
            top: window.pageYOffset + clientRect.top,
            left: window.pageXOffset + clientRect.left + clientRect.width / 2,
            title: `${d.reasonName}; возрастная группа: ${d.ageGroupName}`,
            text: `Потерянных лет: ${format('.3~')(d.value)}`,
            show: true,
          })
        })
        .on('mouseout', () => {
          setTooltip({
            show: false,
          })
        })
        .transition('rectAnimation')
        .duration(this.animationDuration)
        .attr('height', d => yScales[d.reasonName](d.value))
        .attr('y', d => yScaleAllReasons.bandwidth() - yScales[d.reasonName](d.value))
    },
    createYAxis() {
      const { svg, yScaleAllReasons2, width, margin } = this
      svg
        .select('g.y-axis-right')
        .transition()
        .duration(this.animationDuration)
        .call(axisRight().scale(yScaleAllReasons2))
        .selectAll('line')
        .attr('x1', -width + margin.left + margin.right)

      svg.select('g.y-axis-right .domain').remove()
    },
    createXAxis() {
      const { svg, xScale } = this
      svg
        .select('g.x-axis-bottom')
        .call(axisBottom().scale(xScale))
        .selectAll('text')
        .attr('y', 0)
        .attr('x', -9)
        .attr('dy', '.35em')
        .attr('transform', 'rotate(-90)')
        .style('text-anchor', 'end')

      svg.select('g.x-axis-bottom .domain').remove()
    },
    yScale(row) {
      const { scaleLinear, yScaleAllReasons } = this
      return scaleLinear()
        .domain([0, max(row)])
        .range([0, yScaleAllReasons.bandwidth()])
    },
    ...mapActions({ setTooltip: 'tt/setTooltip' }),
  },
}
</script>

<style scoped>
.chart-plot >>> rect:hover {
  fill-opacity: 0.5;
  transition: fill-opacity 0.5s;
}
</style>
