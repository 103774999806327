<template>
  <div class="chart-plot">
    <svg :viewBox="viewBox">
      <g class="content" :transform="contentTranslate"></g>
      <text class="svg-text"></text>
    </svg>
  </div>
</template>

<script>
import {
  select,
  pie,
  arc,
  scaleOrdinal,
  schemeCategory10,
  format,
  interpolate,
  event as d3event,
} from 'd3'
import { mapGetters, mapActions } from 'vuex'

import d3PlotMixin from './d3PlotMixin'

export default {
  mixins: [d3PlotMixin],
  props: {
    height: {
      type: Number,
      default: 230,
    },
    width: {
      type: Number,
      default: 230,
    },
    data: {
      type: Object,
      required: true,
    },
    options: {
      type: Object,
      required: true,
    },
    events: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      /*
        Управлять отступами тоже хочестя из родителя
        А еще можно бросать предупреждение если размера отступов не досатончо для
        расширения при заданном радиусе и размере увеличения
     */
      /*
        Настройки графика
        Возможно стоит перенести в опции?
      */
      padAngle: 0.04,
      innerRadiusRatio: 0.5,
      animationRadiusGrowth: 1.1,
      /*
        Хранение прошлых значений для анимации
      */
      currentArcParams: [],
      lastValue: 0,
    }
  },
  mounted() {
    this.render()
  },
  watch: {
    data: {
      handler() {
        this.render()
      },
    },
    options() {
      this.render()
    },
  },
  computed: {
    ...mapGetters({
      otherId: 'dbd/getOtherID',
    }),
    mainPlotAreaHeight() {
      return this.height - this.options.margin.top - this.options.margin.bottom
    },
    mainPlotAreaWidth() {
      return this.width - this.options.margin.left - this.options.margin.right
    },
    radius() {
      return Math.min(this.mainPlotAreaHeight, this.mainPlotAreaWidth) / 2
    },
    viewBox() {
      return `0 0 ${this.width} ${this.height}`
    },
    tooltip() {
      return select(this.$el).select('.tooltip')
    },
    contentTranslate() {
      return `translate(${this.radius + this.options.margin.left}, ${
        this.radius + this.options.margin.top
      })`
    },
    className() {
      return `chart-plot d-flex`
    },
    selectedIndexes() {
      return this.options.selectedIds.map(d => this.data.itemsId.findIndex(item => item === d))
    },
    arcs() {
      return pie().sort(null)(this.data.values)
    },
    color() {
      return scaleOrdinal(schemeCategory10)
    },
    /* 2do decimal, integer, percent -- лучше сделать глобальными константами*/
    format() {
      const { valueType = 'decimal', decimalDigits } = this.options
      if (valueType === 'decimal') {
        return format(`.${decimalDigits}~f`)
      } else if (valueType === 'integer') {
        return a => parseInt(a)
      } else if (valueType === 'percent') {
        return format(`.${decimalDigits}~%`)
      } else {
        throw Error('Заданный формат преобразования не поддерживается')
      }
    },
    totalSum() {
      return this.data.values.reduce((a, b) => a + b, 0)
    },
  },
  methods: {
    render() {
      if (!this.data.values.length) return false
      this.renderArcs()
      this.renderText()
    },
    renderArcs() {
      const { svg, arcs, arcPrepare, arcAnimation, data } = this

      for (let i = 0; i < arcs.length; i++) {
        arcs[i].itemId = data.itemsId[i]
      }

      svg
        .select('.content')
        .selectAll('path')
        .data(arcs)
        .join(
          enter => enter.append('path').classed('arc', true).call(arcPrepare).call(arcAnimation),
          update => update.call(arcAnimation)
        )
    },
    renderText() {
      const { svg, getReasonsSum, format, options, radius, animationDuration, lastValue } = this
      svg
        .select('.svg-text')
        .attr('text-anchor', 'middle')
        .attr('dominant-baseline', 'middle')
        .attr(
          'transform',
          `translate(${options.margin.left + radius},${options.margin.top + radius})`
        )
        .attr('font-size', 22)
        .attr('font-weight', '450')
        .attr('fill', 'grey')
        .transition()
        .duration(animationDuration)
        .textTween(() => t => {
          return format(lastValue + (getReasonsSum() - lastValue) * t)
        })
        .call(() => {
          this.lastValue = getReasonsSum()
        })
    },
    getReasonsSum() {
      const { selectedIndexes } = this
      const { valueType } = this.options
      let sum = this.totalSum

      if (selectedIndexes.length) {
        sum = this.data.values
          .slice()
          .filter((d, i) => selectedIndexes.includes(i))
          .reduce((a, b) => a + b, 0)
      }

      return valueType === 'percent' ? sum / (this.totalSum || 1) : sum
    },
    arcPrepare(s) {
      const {
        radius,
        padAngle,
        currentArcParams,
        selectedIndexes,
        changeOpacity,
        showTooltip,
        hideTooltip,
        color,
      } = this
      s
        .attr('fill-opacity', 0.3)
        .attr('fill', (d, i) => color(i))
        .attr('stroke', (d, i) => d.data ? color(i) : null)
        .attr('d', (item, index) => {
          currentArcParams.push({
            index,
            selected: selectedIndexes.includes(index),
            startAngle: item.startAngle,
            endAngle: item.startAngle,
          })
          return arc()({
            startAngle: item.startAngle,
            endAngle: item.startAngle,
            innerRadius: radius * 0.5,
            outerRadius: radius,
            padAngle,
          })
        })
        .on('mouseover.internal', () =>
          select(d3event.target).call(changeOpacity, 0.5).call(showTooltip, this)
        )
        .on('mouseout.internal', () =>
          select(d3event.target).call(changeOpacity, 0.3).call(hideTooltip)
        )
        .call(this.bindEvents, this.events)
      return s
    },
    getBoundingClientRectForD3el(d3el) {
      return d3el._groups[0][0].getBoundingClientRect()
    },
    getTooltipPositionWithPx(arc, position) {
      const { getBoundingClientRectForD3el, svg, tooltip } = this
      const tooltipTopMargin = 10
      let value =
        getBoundingClientRectForD3el(arc)[position] - getBoundingClientRectForD3el(svg)[position]
      if (position === 'top')
        value -= getBoundingClientRectForD3el(tooltip).height + tooltipTopMargin
      return value + 'px'
    },
    getTooltipText() {},
    showTooltip(arc) {
      const { data } = this
      const clientRect = arc.node().getBoundingClientRect()
      const arcData = arc.data()[0]
      const { value, itemId } = arcData
      const index = data.itemsId.findIndex(i => i === itemId)
      let name = data.names[index]
      if (data.titlePrefix) {
        name = `${data.titlePrefix}: ${name.toLowerCase()}`
      }
      const text = `Потерянные годы: ${format('.2~')(value)}, доля: ${format('.2~%')(
        value / (this.totalSum || 1)
      )}`
      this.setTooltip({
        show: true,
        top: window.pageYOffset + clientRect.top,
        left: window.pageXOffset + clientRect.left + clientRect.width / 2,
        title: name,
        text,
      })
    },
    hideTooltip() {
      this.setTooltip({
        show: false,
      })
    },
    arcAnimation(s) {
      const { animationDuration, animationFunction, color } = this
      s
        .transition('arcAnimation')
        .duration(animationDuration)
        .attrTween('d', animationFunction)
        .attr('stroke', (d, i) => d.data ? color(i) : null)
      return s
    },
    getInterpolationStartObject(el) {
      const { radius, currentArcParams, innerRadiusRatio, animationRadiusGrowth, padAngle } = this
      let startAngle = 0
      let endAngle = 0
      let innerRadius = radius * innerRadiusRatio
      let outerRadius = radius
      const prev = currentArcParams.find(a => a.index === el.index)
      if (prev) {
        startAngle = prev.startAngle
        endAngle = prev.endAngle
        if (prev.selected) {
          innerRadius *= animationRadiusGrowth
          outerRadius *= animationRadiusGrowth
        }
      }
      return { startAngle, endAngle, innerRadius, outerRadius, padAngle }
    },
    getInterpolationEndObject(el) {
      const { radius, padAngle, animationRadiusGrowth, innerRadiusRatio, selectedIndexes } = this
      el.innerRadius = radius * innerRadiusRatio
      el.outerRadius = radius
      el.padAngle = padAngle
      if (selectedIndexes.includes(el.index)) {
        el.innerRadius *= animationRadiusGrowth
        el.outerRadius *= animationRadiusGrowth
      }
      return {
        startAngle: el.startAngle,
        endAngle: el.endAngle,
        innerRadius: el.innerRadius,
        outerRadius: el.outerRadius,
        padAngle,
      }
    },
    updateCurrentArcParams(el) {
      const { currentArcParams, selectedIndexes } = this
      const prev = currentArcParams.find(a => a.index === el.index)
      prev.startAngle = el.startAngle
      prev.endAngle = el.endAngle
      prev.selected = selectedIndexes.includes(el.index)
    },
    animationFunction(el) {
      const i = interpolate(
        this.getInterpolationStartObject(el),
        this.getInterpolationEndObject(el)
      )
      this.updateCurrentArcParams(el)
      return function (t) {
        return arc()(i(t))
      }
    },
    ...mapActions({ setTooltip: 'tt/setTooltip' }),
  },
}
</script>

<style scoped>
svg >>> .arc {
  cursor: pointer;
}

.chart-plot {
  position: relative;
}

.tooltip {
  position: absolute;
  top: 0;
  left: 0;
  width: 200px;
  height: 40px;
  padding: 6px;
  font: 12px sans-serif;
  background: grey;
  color: white;
  border: 0px;
  border-radius: 8px;
  pointer-events: none;
  opacity: 0;
  transition: all ease-out 0.2s 0.1s;
}
</style>
